<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .el-sel-width {
    width: 100%;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        设备管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>数据采集类型</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getDeviceByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 364px"
              v-model="filters.key"
              placeholder="请输入名称/类型编号"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="false">
            <el-input style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item>
            <!-- <el-button type="primary" @click="getDeviceByName">搜索</el-button> -->
            <el-button type="primary" @click="dataStream">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table
        :data="tableData"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          label="名称"
        ></el-table-column>
        <el-table-column
          prop="code"
          :show-overflow-tooltip="true"
          label="类型编号"
        ></el-table-column>
        <el-table-column
          prop="unitSymbol"
          :show-overflow-tooltip="true"
          label="单位符号"
        ></el-table-column>
        <!-- <el-table-column prop="remarks" min-width="95" :show-overflow-tooltip="true" label="规则引擎">
        </el-table-column> -->

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              @click="handleEdit(scope.$index, scope.row)"
              :disabled="!editPermission"
              size="small"
            >
              修改
            </el-button>
            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <!-- <router-link to="/device/detail"> -->
        <el-button @click="dialogShow = true" type="primary">新增</el-button>
        <!-- </router-link> -->
        <el-button
          type="danger"
          :disabled="!this.sels.length > 0"
          @click="batchRemove"
        >
          批量删除
        </el-button>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      :title="isEdit ? '修改' : '新增数据采集类型'"
      :close-on-click-modal="false"
      size="small"
      :visible.sync="dialogShow"
    >
      <el-form
        ref="addForm"
        :model="dataKindInfo"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="名 称:" prop="name">
          <el-input
            v-model="dataKindInfo.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="数据编码:" prop="code">
          <el-input
            v-model="dataKindInfo.code"
            placeholder="请输入数据编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位符号:">
          <el-input
            v-model="dataKindInfo.unitSymbol"
            placeholder="请输入单位符号"
          ></el-input>
        </el-form-item>
        <el-form-item label="规则引擎:">
          <el-input
            v-model="dataKindInfo.rule"
            :disabled="true"
            placeholder="请输入数据编码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -20px">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="submitDvc">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    dataStream,
    createDataStream,
    updateDataStream,
    delDataStream,
  } from "../../../services/device";
  import { updateDefaultDevice } from "../../../services/ucenter";
  import { getAllCustomers } from "../../../services/customer";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../../config";
  import DeviceDetail from ".././detail.vue";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import { valiInput, valiSelect } from "../../../utils/validator/element";

  export default {
    data() {
      return {
        editPermission: true,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        tableData: [],
        filters: {
          key: "",
          // isProxy: '',
        },
        //下面是对话框中的信息
        dataKindInfo: {
          type: "sensor",
          name: "",
          code: "",
          unitSymbol: "",
          rule: "",
          //remarks: ''
        },
        listLoading: false,
        sels: [], //列表选中列
        dialogShow: false,
        rules: {
          name: [{ validator: valiInput, trigger: "blur" }],
          code: [{ validator: valiInput, trigger: "blur" }],
        },
      };
    },
    components: {
      "device-detail": DeviceDetail,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    watch: {
      dialogShow(newval) {
        if (!newval) {
          this.dataKindInfo = {
            type: "sensor",
            name: "",
            code: "",
            unitSymbol: "",
            rule: "",
          };
        }
      },
    },
    methods: {
      submitDvc() {
        //console.log(this.dataKindInfo)
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.dialogShow = false;
            this.updateDataStream(this.dataKindInfo);
          }
        });
      },
      /**
       * 更新滤芯规则
       */
      async updateDataStream(obj) {
        let res = null;
        if (this.isEdit) {
          res = await updateDataStream(obj);
        } else {
          res = await createDataStream(obj);
        }
        if (res && res.errorCode === 0) {
          this.dataStream();
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
        }
        this.isEdit = false;
      },

      /**
       * 设备编辑权限判断
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "Device:update";
        });
      },
      formatTime(row) {
        return row && row.updateTime
          ? moment(row.updateTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delDataStream(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.dataStream();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.dialogShow = true;
        // console.log(row)
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedRules = Object.assign({}, row);
        this.dataKindInfo = {
          type: "sensor",
          id: choosedRules.id,
          name: choosedRules.name,
          code: choosedRules.code,
          unitSymbol: choosedRules.unitSymbol,
          rule: choosedRules.rule,
        };
      },

      /**
       * 定义序号的排序
       */
      sortDeviceByTime({ column, prop, order }) {
        if (prop === "index") {
          if (order === "ascending") {
            this.pager.sort = "updateTime,asc";
          } else {
            this.pager.sort = "updateTime,desc";
          }
          this.queryDevices();
        }
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        console.log(ids);
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delDataStream(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.dataStream();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据设备名筛选
       */
      getDeviceByName() {
        this.dataStream();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.dataStream();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "data", query: { page: page } });
          this.dataStream();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.dataStream();
      },

      /**
       * 获取产品型号列表
       */
      async dataStream() {
        this.listLoading = true;
        let Param = { type: "sensor", key: this.filters.key };
        // if (Object.prototype.toString.call(searchParam.isProxy) != '[object Boolean]') {
        //   delete searchParam.isProxy;
        // }
        const responseData = await dataStream(Param, this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.tableData = responseData.data.list || [];
          this.total = responseData.data.total;
          // alert(1)
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 处理禁用还是启用
       * @param index 当先列的下标
       * @param row   当前列的数据对象
       * @param disabled Boolean true调用禁用  false调用启用
       */
      async handleDisabled(index, row, disabled) {
        let disableData = null;
        if (disabled) {
          disableData = await disabledDevice(row.id);
        } else {
          disableData = await enabledDevice(row.id);
        }
        if (disableData.errorCode == 0 && disableData.data == "done") {
          row.locked = disabled;
          Notification.success({
            title: "成功",
            message: disabled ? "禁用成功" : "启用成功",
          });
        }
      },
    },
    created() {
      this.dataStream();
    },
  };
</script>
